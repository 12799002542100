import {apiFetch} from "../../../common/services/ApiServiceUtils";
import { ProxyClient, ProxyResponse, Proxy } from "../common/types/proxyTypes";

async function getProxies(): Promise<ProxyClient[]> {
    const response = await apiFetch("/proxy/all", "GET");
    const data = await response.json();
    return data.data.map((proxy: ProxyClient) => ({ ...proxy, selected: false }));
}

async function enableProxiesById(proxies: ProxyResponse[]): Promise<ProxyClient[]> {
    return updateEnabled(proxies, true);
}

async function disableProxiesById(proxies: ProxyResponse[]): Promise<ProxyClient[]> {
    return updateEnabled(proxies, false);
}

async function shuffleProxies(): Promise<boolean> {
    const response = await apiFetch("/proxy/shuffle", "PATCH");
    return response.ok;
}

async function deleteAllProxies(): Promise<boolean> {
    const response = await apiFetch("/proxy/delete-all", "DELETE");
    return response.ok;
}

async function deleteProxiesById(proxyIds: string[]): Promise<boolean> {
    const response = await apiFetch("/proxy/delete", "POST", { proxies: proxyIds });
    return response.ok;
}

async function addProxies(proxies: Proxy[]): Promise<ProxyClient[]> {
    const response = await apiFetch("/proxy/add", "POST", { proxies });
    const data = await response.json();
    return data.map((proxy: ProxyResponse) => ({...proxy, selected: false}));
}

// Helper function to update the 'enabled' field for a list of proxies
async function updateEnabled(proxies: ProxyResponse[], enabled: boolean): Promise<ProxyClient[]> {
    const proxyIds = proxies.map((proxy) => proxy._id);
    const partialProxies = await updateProxies(proxyIds, "enabled", enabled);

    return proxies.map((proxy, index) => {
        proxy.enabled = partialProxies[index].enabled!;
        return {...proxy, selected: false};
    });
}

// Helper function to update the specified field for a list of proxies
async function updateProxies(proxyIds: string[], field: keyof ProxyResponse, value: any): Promise<ProxyResponse[]> {
    console.log(proxyIds.map((id) => ({ _id: id, [field]: value })))
    const response = await apiFetch("/proxy/update", "PATCH", { proxies: proxyIds.map((id) => ({ _id: id, [field]: value })) });
   
    const data = await response.json();
    return data.data;
}

export {
    getProxies,
    enableProxiesById,
    disableProxiesById,
    shuffleProxies,
    deleteAllProxies,
    deleteProxiesById,
    addProxies,
    updateEnabled,
    updateProxies
};
