import {memo} from "react";
import {Cog6ToothIcon} from "@heroicons/react/24/outline";
import { navigation } from "../../constants/navigationConstants";
import { classNames } from "../../utils/utils";


const SideBar = (): JSX.Element => {
    return (
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-neutral-950 px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                    <svg className={"h-8 w-auto fill-white"} viewBox="0 0 120 121" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.09086 106.091C1.35757 106.091 -1.53432 101.352 0.824318 96.4615C4.12172 89.6232 14.9766 86.8489 18.3699 92.7255C21.6503 98.4078 14.4462 106.091 7.09086 106.091ZM4.2086 98.0934C2.14652 102.369 7.30031 103.455 11.4555 101.056C14.7193 99.1714 16.0053 96.1429 15.1168 94.6039C13.5657 91.9187 6.43035 93.4872 4.2086 98.0934Z" fill="white"/>
                        <path d="M33.4459 120.305C25.6268 120.305 23.9832 111.012 31.7584 106.523C36.1556 103.984 41.1551 104.688 43.1388 108.125C46.1807 113.393 40.0576 120.305 33.4459 120.305ZM37.15 108.783C32.8847 108.783 29.4248 113.076 30.7088 115.301C31.5935 116.833 34.5126 116.939 36.9575 115.528C40.7635 113.331 41.5623 108.783 37.15 108.783Z" fill="white"/>
                        <path d="M66.4782 102.097C68.4283 97.5348 75.3664 95.9719 77.7518 100.104C79.2895 102.767 77.7868 106.186 74.8333 107.891C69.5774 110.925 64.4257 106.9 66.4782 102.097ZM73.2512 101.467C71.0205 101.467 69.3365 103.697 69.8841 104.647C70.2447 105.271 71.6343 105.398 72.9549 104.637C74.7054 103.626 75.4305 101.467 73.2512 101.467Z" fill="white"/>
                        <path d="M64.0423 10.5456C57.9659 17.0895 54.4809 25.582 54.4809 33.8461C54.4809 67.3987 24.4255 77.1528 24.4255 86.443C24.4255 88.6307 25.3796 89.78 26.18 90.3593C28.1165 91.7599 31.0196 91.2718 33.9702 90.4063L33.9573 90.3786C49.3828 83.1195 50.5044 77.1307 53.0063 79.8193C53.0143 79.8428 54.2508 81.3011 52.8746 82.5074C48.8383 86.0235 44.4316 89.0902 39.7322 91.6536C41.4803 100.011 53.4543 94.1742 60.3077 88.1883C61.6266 87.0363 62.968 88.317 62.903 88.2904C63.0824 88.4699 63.2236 88.6839 63.3178 88.9196C63.4121 89.1552 63.4575 89.4075 63.4513 89.6612C63.4451 89.9149 63.3874 90.1647 63.2818 90.3955C63.1762 90.6262 63.0248 90.8331 62.8368 91.0036C61.0436 92.6299 59.0813 94.1606 57.5189 95.3175C60.9957 96.8672 64.4548 94.7206 67.6743 92.7389C72.6184 89.696 73.6692 89.3513 75.824 91.8863C76.905 93.1563 78.3829 94.896 81.249 94.896C83.9704 94.896 93.6832 90.0001 102.546 80.6406C110.508 72.2324 120 57.8713 120 37.2045C119.999 3.77165 84.9688 -11.99 64.0423 10.5456ZM57.0617 78.483L54.3276 75.9062C55.1486 75.0483 55.9256 74.1494 56.6555 73.2127L59.6479 75.484C58.8378 76.5273 57.9746 77.5283 57.0617 78.483ZM88.2932 45.1168C85.1332 45.1168 82.6578 41.8164 82.6578 37.603C82.6578 33.3896 85.1332 30.0891 88.2932 30.0891C91.4533 30.0891 93.9286 33.3896 93.9286 37.603C93.9286 41.8164 91.4533 45.1168 88.2932 45.1168ZM107.078 45.1168C103.918 45.1168 101.442 41.8164 101.442 37.603C101.442 33.3896 103.918 30.0891 107.078 30.0891C110.238 30.0891 112.713 33.3896 112.713 37.603C112.713 41.8164 110.238 45.1168 107.078 45.1168Z" fill="white"/>
                    </svg>
                </div>
                <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                            <ul role="list" className="-mx-2 space-y-1">
                                {navigation.map((item) => (
                                    <li key={item.name}>
                                        <a
                                            href={item.href}
                                            className={classNames(
                                                item.current
                                                    ? 'bg-neutral-800 text-white'
                                                    : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                            )}
                                        >
                                            <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </li>
                        <li className="mt-auto">
                            <a
                                href="#"
                                className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                            >
                                <Cog6ToothIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                Settings
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default memo(SideBar);