import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyCNvH7oGGl96ZUQxG3WRpBobHI9RXn9650",
    authDomain: "seatedlabs-4cd67.firebaseapp.com",
    projectId: "seatedlabs-4cd67",
    storageBucket: "seatedlabs-4cd67.firebasestorage.app",
    messagingSenderId: "919104068417",
    appId: "1:919104068417:web:2d82783b4a33fdf6ce9fc0",
    measurementId: "G-ZYJN4C2CTH"
};

const app = initializeApp(firebaseConfig);

export default app;
