interface CheckBoxProps {
    isChecked: boolean;
    onChecked: (checked: boolean) => void;
}

const CheckBox = ({isChecked, onChecked}: CheckBoxProps) => {
    const onCheckedHandler = (ev: any) => {
        const checkValue = ev.target.checked;
        onChecked(checkValue)
    }
    return (
        <input
            type="checkbox"
            className=" left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            checked={isChecked}
            onChange={onCheckedHandler}
        />      
    );
};

export default CheckBox;