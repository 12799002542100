import {useState, memo, useCallback} from "react";
import { ProxyClient } from "../../../../common/types/proxyTypes";
import ProxyButtons from "../../ProxyButtons";
import ProxyListHeader from "./ProxyListHeader";
import ProxyListRow from "./ProxyListRow";
import ProxyListTableHeader from "./ProxyListTableHeader";

interface ProxyListProps {
    title: string;
    subtitle?: string;
    selectedProxies: number;
    onAddProxy: () => void;
    proxies: ProxyClient[];
    onEnable: () => void;
    onDisable: () => void;
    onDeleteProxies: () => void;
    // onDeleteCookies: () => void;
    onDeleteAllProxies: () => void;
    // onDeleteAllCookies: () => void;
    onSelectProxy: (proxyClient: ProxyClient, selected: boolean) => void;
    onToggleSelectAll: (selected: boolean, indeterminate: boolean) => void;
    onShuffleProxies: () => void;
}


const ProxyList = (props: ProxyListProps): JSX.Element => {
    const {
        title,
        subtitle,
        onAddProxy,
        proxies,
        onSelectProxy,
        onToggleSelectAll,
        selectedProxies,
        onEnable,
        onDisable,
        onDeleteProxies,
        onDeleteAllProxies,
        onShuffleProxies,
    } = props;

    const [allSelected, setAllSelected] = useState(false);

    const onAddProxyHandler = useCallback(() => {
        onAddProxy()
    }, []);

    const onToggleSelectAllHandler = useCallback((selected: boolean, indeterminate: boolean) => {
        setAllSelected(selected);
        onToggleSelectAll(selected, indeterminate)
    }, []);

    const onSelectProxyHandler = useCallback((proxy: ProxyClient, selected: boolean) => {
        onSelectProxy(proxy, selected);
    }, [])
    const countSelected = (count: number, proxy: ProxyClient) => count + Number(proxy.selected);
    const selectedCount = proxies.reduce(countSelected, 0);
   
    const isIndeterminate = selectedCount > 0 && selectedCount < proxies.length;

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <ProxyListHeader title={title} subtitle={subtitle} addProxy={onAddProxyHandler} />
            <ProxyButtons
                proxiesSelected={Boolean(selectedProxies)}
                selectedProxies={selectedProxies}
                onEnable={onEnable}
                onDisable={onDisable}
                onDeleteProxies={onDeleteProxies}
                // onDeleteCookies={onDeleteCookies}
                // onDeleteAllCookies={onDeleteAllCookies}
                onDeleteAllProxies={onDeleteAllProxies}
                onShuffleProxies={onShuffleProxies}
                isAllSelected={allSelected}
            />
            <div className="mt-4 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <ProxyListTableHeader  onToggleSelectAll={onToggleSelectAllHandler} isIndeterminate={isIndeterminate}/>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {proxies.map((proxy) => (
                                    <ProxyListRow
                                        key={proxy._id}
                                        proxy={proxy}
                                        onSelectProxy={onSelectProxyHandler}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(ProxyList);