import { useRef } from "react";

interface SelectAllToggleProps {
    isIndeterminate: boolean;
    onToggleSelectAll: (checked: boolean, indeterminate: boolean) => void;
}

const SelectAllToggle = ({isIndeterminate, onToggleSelectAll}: SelectAllToggleProps) => {
    const checkBox = useRef<any>(null);

    
    if (checkBox.current) checkBox.current.indeterminate = isIndeterminate;

    const onToggleSelectAllHandler = async (ev: any) => {
        const checked = ev.target.checked;
        const indeterminate = ev.target.indeterminate;
        onToggleSelectAll(checked, indeterminate);
    };

    return (
        <input
            type="checkbox"
            className="left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            ref={checkBox}
            onChange={onToggleSelectAllHandler}
        />
    )
};

export default SelectAllToggle;