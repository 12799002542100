import {ProxyClient} from "../types/proxyTypes";
import {useState, useCallback, useEffect} from "react";
import * as ProxyService from "../../services/ProxyService";

export const useGetProxies = (): [ProxyClient[], () => void, Error | null] => {
    const [getProxyState, setGetProxyState] = useState<{ proxies: ProxyClient[]; error: Error | null }>({
        proxies: [],
        error: null,
    });
    const getProxies = useCallback(async (): Promise<void> => {
        try {
            const proxies = await ProxyService.getProxies();
            setGetProxyState({ proxies, error: null });
        } catch (error: any) {
            setGetProxyState({ proxies: [], error });
        }
    }, []);

    useEffect(() => {
        getProxies();
    }, [])

    return [getProxyState.proxies, getProxies, getProxyState.error];
};