import {useState} from "react";
import {ArrowsUpDownIcon, TrashIcon} from "@heroicons/react/24/outline";
import { classNames } from "../../../../common/utils/utils";
import { ProxyButtonList } from "./constants/listButtonConstants";
import { ProxyAction } from "./constants/listConstants";



interface ProxyButtonsProps {
    proxiesSelected: boolean;
    selectedProxies: number;
    isAllSelected: boolean;
    onEnable: () => void;
    onDisable: () => void;
    onDeleteProxies: () => void;
    // onDeleteCookies: () => void;
    onDeleteAllProxies: () => void;
    // onDeleteAllCookies: () => void;
    onShuffleProxies: () => void;
}

interface ButtonProps {
    title: string;
    onClick: () => void;
    bkgColor: string;
    hoverColor: string;
    focusOutlineColor: string;
    icon?: JSX.Element;
    outlineColor: string;
    textColor: string;
}

const ProxyButton = ({title, onClick, bkgColor, hoverColor, focusOutlineColor, icon, outlineColor, textColor}: ButtonProps): JSX.Element => {

    return (
        <button
            type="button"
            onClick={onClick}
            className={classNames(bkgColor, hoverColor,outlineColor, textColor, `text-left rounded-md px-2 py-1 text-sm font-semibold shadow-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${focusOutlineColor}`)}
        >
            <div className={"inline-flex align-middle"}>
                {icon}
                <p className={"line-clamp-1"}>{title}</p>
            </div>
        </button>
    );
};

const ProxyButtons = (props: ProxyButtonsProps): JSX.Element => {
    const {
        onEnable,
        onDisable,
        onDeleteProxies,
        // onDeleteCookies,
        // onDeleteAllCookies,
        proxiesSelected,
        selectedProxies,
        onDeleteAllProxies,
        onShuffleProxies,
        isAllSelected
    } = props;
    const [hoverState, setHoverState] = useState<{isHover: boolean, id: ProxyAction | undefined}>({isHover: false, id: undefined});
    const actions: {[key in ProxyAction]: () => void} = {
        [ProxyAction.Enable]: onEnable,
        [ProxyAction.Disable]: onDisable,
        [ProxyAction.DeleteProxy]: onDeleteProxies,
        // [ProxyAction.DeleteCookies]: onDeleteCookies,
        // [ProxyAction.DeleteAllCookies]: onDeleteAllCookies,
        [ProxyAction.DeleteAllProxies]: onDeleteAllProxies,
        [ProxyAction.ShuffleProxies]: onShuffleProxies
    }

    const icons: {[key in ProxyAction]: JSX.Element | undefined} = {
        [ProxyAction.Enable]: undefined,
        [ProxyAction.Disable]: undefined,
        [ProxyAction.DeleteProxy]: <TrashIcon className={"mr0 h-5 w-5"} aria-hidden={true} />,
        // [ProxyAction.DeleteCookies]: <XCircleIcon className={"mr-0 h-5 w-5"} />,
        // [ProxyAction.DeleteAllCookies]: <XCircleIcon className={"mr-0 h-5 w-5"} />,
        [ProxyAction.DeleteAllProxies]: <TrashIcon className={"mr-0 h-5 w-5"} />,
        [ProxyAction.ShuffleProxies]: <ArrowsUpDownIcon className={"mr-0 h-5 w-5"} />
    };

    const getActions = (id: ProxyAction, isProxySelected: boolean, allSelected: boolean): () => void => {

        switch(id) {
            case ProxyAction.DeleteAllProxies:
                return isProxySelected && !allSelected ? actions[ProxyAction.DeleteProxy] : actions[ProxyAction.DeleteAllProxies];
            // case ProxyAction.DeleteAllCookies:
            //     return isProxySelected && !allSelected ? actions[ProxyAction.DeleteCookies] : actions[ProxyAction.DeleteAllCookies];
            default:
                return () => {};
        }
    };

    const shuffleButton = ProxyButtonList[2];
    return (
        <div className={"container inline-flex lg:px-0 pt-4 gap-x-4"}>
            <div className={"container pt-4 flex items-end"}><p className={"line-clamp-1 text-sm  h-fit font-bold"}> {`(${selectedProxies}) proxies selected`}</p></div>
            <div className={"container flex lg:px-0 pt-4 gap-x-4 justify-end"}>
                {proxiesSelected ?
                    ProxyButtonList.slice(0, 2).map((button) => <ProxyButton
                        key={button.id}
                        title={button.title}
                        onClick={actions[button.id]}
                        bkgColor={button.bkgColor}
                        hoverColor={button.hoverColor}
                        focusOutlineColor={button.focusOutlineColor}
                        outlineColor={button.outlineColor}
                        icon={icons[button.id]}
                        textColor={button.textColor}
                    />)
                    : <ProxyButton
                        key={shuffleButton.id}
                        title={shuffleButton.title}
                        onClick={actions[shuffleButton.id]}
                        bkgColor={shuffleButton.bkgColor}
                        hoverColor={shuffleButton.hoverColor}
                        focusOutlineColor={shuffleButton.focusOutlineColor}
                        outlineColor={shuffleButton.outlineColor}
                        textColor={shuffleButton.textColor}
                        icon={icons[shuffleButton.id]}
                    />
                }
                {
                    ProxyButtonList.slice(3).map((button) => <ProxyButton
                        key={button.id}
                        title={button.title}
                        onClick={getActions(button.id, proxiesSelected, isAllSelected)}
                        bkgColor={button.bkgColor}
                        hoverColor={button.hoverColor}
                        focusOutlineColor={button.focusOutlineColor}
                        icon={icons[button.id]}
                        outlineColor={button.outlineColor}
                        textColor={button.textColor}
                    />)
                }
            </div>
        </div>
    );
}

export default ProxyButtons;