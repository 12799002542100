import {getAuth, User} from 'firebase/auth';
import app from "../../config/config";

const auth = getAuth(app);
const BASE_URL = "https://staging-seatedlabs-4e52.encr.app";
async function getAuthHeaders(): Promise<HeadersInit> {
    const user = auth.currentUser;
    if(!user){
        throw new Error("User not signed in");
    }
    const token = await user.getIdToken();

    const headers: HeadersInit = {
        'Authorization': `Bearer ${token}`
    }

    return headers;
}

async function apiFetch(
    endpoint: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    body?: any,
    options: RequestInit = {}
) : Promise<Response>{
    const headers = await getAuthHeaders();
    const url = `${BASE_URL}${endpoint}`;

    const defaultOptions: RequestInit = {
        method,
        headers: {
            ...headers,
            'Content-Type' : 'application/json'
        },
    };

    if (body) {
        defaultOptions.body = JSON.stringify(body)
    }

    const requestOptions = {...defaultOptions, ...options};

    return fetch(url, requestOptions)
}

export {apiFetch}
