import { ProxyAction } from "./listConstants";

export const ProxyButtonList = [
    {
        id: ProxyAction.Enable,
        title: "Enable",
        bkgColor: 'bg-white',
        outlineColor: 'border-neutral-400 border',
        hoverColor: 'hover:border-green-700',
        focusOutlineColor: 'bg-green-700',
        textColor: 'text-black hover:text-green-600'
    },
    {
        id: ProxyAction.Disable,
        title: "Disable",
        bkgColor: 'bg-white',
        outlineColor: 'border-neutral-400 border',
        hoverColor: 'hover:border-red-700',
        focusOutlineColor: 'bg-red-700',
        textColor: 'text-black hover:text-red-700'
    },
    {
        id: ProxyAction.ShuffleProxies,
        title: "",
        bkgColor: 'bg-white',
        outlineColor: 'border-neutral-400 border',
        hoverColor: 'hover:bg-neutral-300',
        focusOutlineColor: 'bg-neutral-700',
        textColor: 'text-black'
    },
    // {
    //     id: ProxyAction.DeleteAllCookies,
    //     title: "",
    //     bkgColor: 'bg-white',
    //     outlineColor: 'border-neutral-400 border',
    //     hoverColor: 'hover:border-red-700',
    //     focusOutlineColor: 'bg-red-700',
    //     textColor: 'hover:text-red-700 text-black'
    // },
    {
        id: ProxyAction.DeleteAllProxies,
        title: "",
        bkgColor: 'bg-white',
        outlineColor: 'border-neutral-400 border',
        hoverColor: 'hover:border-red-700',
        focusOutlineColor: 'bg-red-700',
        textColor: 'hover:text-red-700 text-black'
    }
];