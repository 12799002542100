import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { memo } from "react";

interface ProxyListHeaderProps {
    title: string;
    subtitle?: string;
    addProxy: () => void;
}

const ProxyListHeader = ({title, subtitle, addProxy}: ProxyListHeaderProps): JSX.Element => {
    return (
            <div className="sm:flex sm:items-center mt-4 mb-4 ">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6">{title}</h1>
                    {subtitle && <p className="mt-2 text-sm text-neutral-400">{subtitle}</p>}
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                        type="button"
                        onClick={addProxy}
                        className="inline-flex align-middle block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        <ArrowUpTrayIcon className={"mr-2 h-4 w-4"} />
                        Add Proxy
                    </button>
                </div>
            </div>
    );
};

export default memo(ProxyListHeader);