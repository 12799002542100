import {memo} from "react";

interface ProxyStats {
    totalCookies: number;
    totalProxies: number;
    totalTimesBlocked: number;
    totalTimesBlocked24h: number;
    proxiesEnabled: number;
}
const ProxyStats = ({totalCookies, totalProxies, totalTimesBlocked, totalTimesBlocked24h, proxiesEnabled}: ProxyStats): JSX.Element => {
    return (
        <div className="grid grid-cols-1 gap-px bg-neutral-200 sm:grid-cols-2 lg:grid-cols-5 rounded-2xl my-8">
            <div key={"Total Cookies"} className="px-4 py-6 sm:px-6 lg:px-8">
                <p className="text-sm font-medium leading-6 text-gray-400">{"Total Cookies"}</p>
                <p className="mt-2 flex items-baseline gap-x-2">
                    <span className="text-4xl font-semibold tracking-tight">{totalCookies}</span>
                </p>
            </div>
            <div key={"Total Proxies"} className="px-4 py-6 sm:px-6 lg:px-8">
                <p className="text-sm font-medium leading-6 text-gray-400">{"Total Proxies"}</p>
                <p className="mt-2 flex items-baseline gap-x-2">
                    <span className="text-4xl font-semibold tracking-tight">{totalProxies}</span>
                </p>
            </div>
            <div key={"Proxies Enabled"} className="px-4 py-6 sm:px-6 lg:px-8">
                <p className="text-sm font-medium leading-6 text-gray-400">{"Proxies Enabled"}</p>
                <p className="mt-2 flex items-baseline gap-x-2">
                    <span className="text-4xl font-semibold tracking-tight">{proxiesEnabled}</span>
                </p>
            </div>
            <div key={"Blocked"} className="px-4 py-6 sm:px-6 lg:px-8">
                <p className="text-sm font-medium leading-6 text-gray-400">{"Blocked"}</p>
                <p className="mt-2 flex items-baseline gap-x-2">
                    <span className="text-4xl font-semibold tracking-tight">{totalTimesBlocked}</span>
                </p>
            </div>
            <div key={"Total Times Blocked in 24hrs"} className="px-4 py-6 sm:px-6 lg:px-8">
                <p className="text-sm font-medium leading-6 text-gray-400">{"Blocked (24hrs)"}</p>
                <p className="mt-2 flex items-baseline gap-x-2">
                    <span className="text-4xl font-semibold tracking-tight">{totalTimesBlocked24h}</span>
                </p>
            </div>
        </div>
    );
};

export default memo(ProxyStats);