import React, {
    useState, 
    useEffect, 
    useCallback, 
    useContext, 
    useMemo, 
    Suspense, 
    lazy,
    createElement,
    FunctionComponentElement,
    useReducer,
}from 'react';
import SideBar from "./common/components/SiteNav/SideBar";
import SiteHeader from "./common/components/SiteNav/SiteHeader";
import PageHeader from "./common/components/Page/PageHeader";
import ProxyStats from "./features/proxies/components/ProxyStats/ProxyStats";
import ProxyList from "./features/proxies/components/ProxyList/components/ProxyList/ProxyList";
import { useGetProxies } from "./features/proxies/common/helpers/ProxyApiHooks";
import { ProxyActionType, ProxyState, ProxyStateContext } from './features/proxies/common/state/useProxyState';
import PaginationController from './features/proxies/components/ProxyList/components/PaginationController/PaginationController';
import * as ProxyService from './features/proxies/services/ProxyService';
import SideBarMobile from './common/components/SiteNav/SideBarMobile';
import { ProxyClient } from './features/proxies/common/types/proxyTypes';
const AddProxyDialog = lazy(() => import('./features/proxies/components/AddProxyDialog/AddProxyDialog'));

interface PaginationState {
    start: number;
    end: number;
}

interface PaginationAction {
    isNext: boolean
}

const paginationReducer = (state: PaginationState, action: PaginationAction): PaginationState => {
    if (action.isNext) {
        return {
            start: state.start + 25,
            end: state.end + 25,
        };
    } else {
        return {
            start: state.start - 25,
            end: state.end - 25
        };
    }
};

function App() {
    const [proxies, getProxies] = useGetProxies();
    const [isSideBarOpen, toggleSideBar] = useState(false);
    const [isAddProxyDialogOpen, toggleAddProxyDialog] = useState(false);
    const [currentPage, pageAction] = useReducer(paginationReducer, {start: 0, end: 25});
    const {state: proxiesState, dispatch} = useContext(ProxyStateContext);

    const proxyStateArray = Object.values(proxiesState).sort((proxyA: ProxyClient, proxyB: ProxyClient) => proxyA.proxyOrder - proxyB.proxyOrder);
    const proxyPage = proxyStateArray.slice(currentPage.start, currentPage.end);
    const forSelected = (proxy: ProxyClient) => proxy.selected;
    const selectedProxies = proxyStateArray.filter(forSelected);
    const countReducer = (count: number, proxy: ProxyClient) => count + Number(proxy.enabled);
    const enabledCount = useMemo(() => proxyStateArray.reduce(countReducer, 0), [proxyStateArray]);
    const openSideBar = useCallback(() => toggleSideBar(true), []);
    const closeSideBar = useCallback(() => toggleSideBar(false), []);

    const onCloseDialog = useCallback((proxies?: ProxyClient[]) => {
        toggleAddProxyDialog(false);
    }, []);

    const onOpenDialog = useCallback(() => {
        toggleAddProxyDialog(true);
    }, []);

    const onSelectProxy = useCallback((proxy: ProxyClient, selected: boolean) => {
        dispatch?.({type: ProxyActionType.ToggleSelect, proxies: [proxy], value: selected})
    }, []);

    const onSelectAll = useCallback((selected: boolean, indeterminate: boolean) => {
        dispatch?.({type: ProxyActionType.ToggleSelectAll, value: selected})
    }, []);

    const onEnable = useCallback(async () => {
        const proxiesTemp = selectedProxies;
        dispatch?.({type: ProxyActionType.ToggleEnable, value: true})
        try { 
            await ProxyService.enableProxiesById(selectedProxies);
         } catch (error) {
            console.error(error);
            dispatch?.({type: ProxyActionType.Revert, proxies: proxiesTemp})
         }
    }, [selectedProxies]);

    const onDisable = useCallback(async () => {
        const proxiesTemp = selectedProxies;
        dispatch?.({type: ProxyActionType.ToggleEnable, value: false})
        try { 
            await ProxyService.disableProxiesById(selectedProxies);
         } catch (error) {
            console.error(error);
            dispatch?.({type: ProxyActionType.Revert, proxies: proxiesTemp})
         }
    }, [selectedProxies]);

    const onDelete = useCallback(async () => {
        const proxiesTemp = selectedProxies;
        const selectedProxyIds = selectedProxies.map((proxy: ProxyClient) => proxy._id)
        dispatch?.({type: ProxyActionType.DeleteProxies})
        try { 
            await ProxyService.deleteProxiesById(selectedProxyIds);
         } catch (error) {
            console.error(error);
            dispatch?.({type: ProxyActionType.Revert, proxies: proxiesTemp});
         }

         await ProxyService.shuffleProxies();
         getProxies();
    }, [selectedProxies]);

    const onDeleteAll = useCallback(async () => {
        const proxiesTemp = proxyStateArray;
        dispatch?.({type: ProxyActionType.DeleteAllProxies});
        try {
            const deleted = await ProxyService.deleteAllProxies();
            if (!deleted) throw new Error();
        } catch (error) {
            dispatch?.({type: ProxyActionType.Revert, proxies: proxiesTemp});
        }
    }, [proxyStateArray]);

    const onShuffle = useCallback(async () => {
        const proxiesTemp = selectedProxies;
        dispatch?.({type: ProxyActionType.Revert, proxies:[]});

        try {
            const shuffleSuccessful = await ProxyService.shuffleProxies();
            if (!shuffleSuccessful) throw new Error();
        } catch (error) {
            dispatch?.({type: ProxyActionType.Revert, proxies: proxiesTemp});
        }
    
        getProxies();
       
    }, [selectedProxies]);

    useEffect(() => {
        if (proxies && proxies.length > 0) {
            dispatch?.({type: ProxyActionType.AddProxies, proxies})
        }
    }, [proxies]);

    const onPrevious = () => {
        pageAction({isNext: false});
    };

    const onNext = () => {
        pageAction({isNext: true});
    };

    return (
        <>
            <SideBarMobile isOpen={isSideBarOpen} closeSideBar={closeSideBar} />
            <SideBar />
            <SiteHeader openSideBar={openSideBar} />
            <div className="lg:pl-72 flex grow">
                <main className="py-10 flex grow"> 
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex grow">
                        <div className="mx-auto max-w-5xl flex flex-col">
                            <PageHeader title={"Proxies"} />
                            <ProxyStats
                                totalCookies={0}
                                totalProxies={proxyStateArray.length}
                                totalTimesBlocked={0}
                                totalTimesBlocked24h={0}
                                proxiesEnabled={enabledCount}
                            />
                            <ProxyList
                                title={"Proxies"}
                                subtitle={"List of proxies added to the system"}
                                onAddProxy={onOpenDialog}
                                proxies={proxyPage}
                                onSelectProxy={onSelectProxy}
                                onToggleSelectAll={onSelectAll}
                                onEnable={onEnable}
                                onDisable={onDisable}
                                onDeleteProxies={onDelete}
                                onDeleteAllProxies={onDeleteAll}
                                onShuffleProxies={onShuffle}
                                selectedProxies={selectedProxies.length}
                            />
                            <PaginationController 
                                start={currentPage.start + 1} 
                                end={currentPage.start + proxyPage.length} 
                                onPrevious={onPrevious} 
                                onNext={onNext} 
                                total={proxyStateArray.length}                            
                            />
                            <Suspense>
                                <AddProxyDialog 
                                    isOpen={isAddProxyDialogOpen}
                                    onClose={onCloseDialog}
                                    numberOfProxies={proxyStateArray.length}
                                />
                            </Suspense>           
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default App;
