import ghostlogo from "../../../assets/ghost.svg";
import { useRef } from "react";

interface SignInPageProps {
    onSignIn: (email: string, password: string) => void;
}

const SignInPage = (props: SignInPageProps) => {
    const email = useRef<string>("");
    const password = useRef<string>("");

    const onSignInHandler = () => {
        props.onSignIn(email.current, password.current);
    }

    const onEmailChange = (ev: any) => {
        email.current = ev.target.value;
    };

    const onPasswordChange = (ev: any) => {
        password.current = ev.target.value;
    };

    return (
        <div className="flex min-h-full flex-1 flex-col h-full justify-center px-6 py-12 lg:px-8 h-100">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-12"
            src={ghostlogo}
            alt="Your Company"
          />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" method="POST">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={onEmailChange}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={onPasswordChange}
                />
              </div>
            </div>
            <div>
              <button
                type="button"
                onClick={onSignInHandler}
                className="flex w-full justify-center rounded-md bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    );
}; 

export default SignInPage;