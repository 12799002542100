export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

export const isValidIpAddress = (IpAddress: string): boolean => {
    // const regex = new RegExp(
    //     /^(?!:\/\/)([a-zA-Z0-9-_]+\.[a-zA-Z]{2,}|((25[0-5]|2[0-4][0-9]|1\d{2}|\d{1,2})(\.(?!$)|$)){4})$/
    // );    return regex.test(IpAddress);
    return true;
};

export const isValidPort = (port: string): boolean => {
    const portNumber = Number(port);
    return portNumber !== Number.NaN && portNumber > 0 && portNumber < 65536;
};