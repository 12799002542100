import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, UserCredential, signOut, User} from "firebase/auth";
import { useState, useCallback, useEffect } from "react";
import app from "../../config/config";

const auth = getAuth(app);
const useAuthHook = (): [User | null, any, (email: string, password: string) => void, () => void] => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [error, setError] = useState<any>();

    const onUserChange = useCallback((user: User | null) => {
        setCurrentUser(user)
    }, [])

    useEffect(() => {
        onAuthStateChanged(auth, onUserChange);
    }, [])

    const onSignIn = useCallback(async (email: string, password: string) => {
        let userCredential: UserCredential | undefined;
        try {
            userCredential = await signInWithEmailAndPassword(auth, email, password);
        } catch(error) {
            console.error(error);
            setError(error);
        }

        if (!userCredential) setError(new Error("Auth error occured"));
        
        let idToken: string;
        try {
             idToken = await userCredential!.user.getIdToken();
        } catch (error) {
            console.error(error);
            setError(error);
        }
    }, []);

    const onSignOut = useCallback(async () => {
        try {
            await signOut(auth);
        } catch (error) {
            console.error(error);
            setError(error);
        }
    }, []);

    return [currentUser, error, onSignIn, onSignOut];
};

export default useAuthHook;