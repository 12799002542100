import { memo, useCallback } from "react";
import { ProxyClient } from "../../../../common/types/proxyTypes";
import { classNames } from "../../../../../../common/utils/utils";
import { statuses } from "../../constants/listRowConstants";
import CheckBox from "./CheckBox";

interface ProxyListRowProps {
    proxy: ProxyClient;
    onSelectProxy: (proxy: ProxyClient, selected: boolean) => void;
}

const ProxyListRow = ({proxy, onSelectProxy}: ProxyListRowProps): JSX.Element => {
    const onSelectItem = useCallback((e: any) => {
        onSelectProxy(proxy, !proxy.selected);
    }, [proxy]);
    return (
        <tr key={proxy._id} className="divide-x divide-gray-200">
            <td className="whitespace-nowrap p-4 text-sm text-center text-gray-500">
            <CheckBox isChecked={proxy.selected} onChecked={onSelectItem} />
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-center font-medium text-gray-900">
                {proxy.proxyOrder}
            </td>
            <td className="whitespace-nowrap p-4 text-sm text-center text-gray-500">{proxy.ip}</td>
            <td className="whitespace-nowrap p-4 text-sm text-right text-gray-500">{proxy.totalCookies}</td>
            <td className="whitespace-nowrap p-4 text-sm text-right  text-gray-500">{proxy.totalBlocked}</td>
            <td className="whitespace-nowrap p-4 text-sm text-center items-center text-gray-500">
                <div className={"inline-flex items-center"}>
                    <div className={classNames(statuses[proxy.enabled ? "Enabled" : "Disabled"], 'rounded-full p-1 h-fit w-fit mr-0')}>
                        <div className="h-1.5 w-1.5 rounded-full bg-current" />
                    </div>
                    <div className={"ml-1"}>{`${proxy.enabled}`}</div>
                </div>

            </td>
            {/*<td className="whitespace-nowrap p-4 text-sm text-center text-gray-500">*/}
            {/*    <CookieButton title={proxy.tmCookieEnabled ? 'Disable' : 'Enable'} onClick={toggleTMCookie} />*/}
            {/*</td>*/}
        </tr>
    );
};

export default memo(ProxyListRow);