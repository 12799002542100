import {memo}from "react";

interface PageHeaderProps {
    title: string;
}
const PageHeader = ({title}: PageHeaderProps): JSX.Element => {
    return (
        <div className="md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
                <h2 className="text-2xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
                    {title}
                </h2>
            </div>
        </div>
    );
};

export default memo(PageHeader);