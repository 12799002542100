import { memo } from "react";
import SelectAllToggle from "./SelectAllToggle";

interface ProxyListTableHeaderProps {
    onToggleSelectAll: (selected: boolean, isIndeterminate: boolean) => void;
    isIndeterminate: boolean;
}

const ProxyListTableHeader = ({onToggleSelectAll,  isIndeterminate}: ProxyListTableHeaderProps): JSX.Element => {
    const toggleSelectAllHandler = (checked: boolean, indeterminate: boolean) => {
        onToggleSelectAll(checked, indeterminate);
    };
    return (
        <thead className={"bg-gray-700 text-white bg-gray-950"}>
            <tr className="divide-x divide-gray-200">
                <th scope="col" className="p-4 text-center text-sm font-semibold">
                    <SelectAllToggle 
                        isIndeterminate={isIndeterminate}
                        onToggleSelectAll={toggleSelectAllHandler}

                    />
                </th>
                <th scope="col" className="p-4 text-center text-sm font-semibold">
                    Order
                </th>
                <th scope="col" className="p-4 text-center text-sm font-semibold">
                    IP
                </th>
                <th scope="col" className="p-4 text-left text-sm font-semibold">
                    Total Cookies
                </th>
                <th scope="col" className="p-4 text-left text-sm font-semibold">
                    Total Blocked
                </th>
                <th scope="col" className="p-4 text-center text-sm font-semibold">
                    Enabled
                </th>
            </tr>
        </thead>
    );
};

export default memo(ProxyListTableHeader);