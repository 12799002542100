import {PropsWithChildren, createContext, useReducer, useMemo, Dispatch} from "react";
import { ProxyClient, ProxyResponse } from "../types/proxyTypes";
export const ProxyStateContext = createContext<{ state: ProxyState, dispatch?: Dispatch<ProxyAction>}>({state: {}});

export type ProxyState = {[ip: string]: ProxyClient}

export enum ProxyActionType {
    ToggleEnable,
    DeleteAllProxies,
    DeleteProxies,
    AddProxies,
    ToggleSelect,
    ToggleSelectAll,
    Revert,
}

export type ProxyClientIp = string;

export interface ProxyAction {
    type: ProxyActionType;
    proxies?: ProxyClient[];
    value?: any;
}

const fieldReducer =  <TypeField extends keyof ProxyClient>(field: TypeField, value: ProxyClient[TypeField]) => {
    return (state: ProxyState, proxy: ProxyClient) => {
        return {...state, [proxy.ip]: {...proxy, [field]: value}};
    };
};

export const ProxyReducer = (proxyState: ProxyState, proxyAction: ProxyAction): ProxyState => {
    let newState: ProxyState;
    const selected: ProxyClient[] = Object.values(proxyState).filter((proxyClient: ProxyClient) => proxyClient.selected);
    switch (proxyAction.type) {
        case ProxyActionType.AddProxies:
            if (!proxyAction.proxies) break;
            const proxiesToAdd = (state: ProxyState, proxy: ProxyResponse) => {
                return {...state, [proxy.ip]: {...proxy, selected: false}}
            };
            return {...proxyState, ...proxyAction.proxies.reduce(proxiesToAdd, {})}
        case ProxyActionType.DeleteProxies:
            const proxiesToDelete = new Set<string>();
            const addToDeleteSet = (proxy: ProxyClient) => {
                proxiesToDelete.add(proxy.ip);
            };
            selected.forEach(addToDeleteSet);
            const reduceToKeep = (state: ProxyState, proxy: ProxyClient) => {
                return proxiesToDelete.has(proxy.ip) ? {...state} : {...state, [proxy.ip]: {...proxy}};
            };
            newState = {...Object.values(proxyState).reduce(reduceToKeep, {})};
            return newState;
        case ProxyActionType.ToggleEnable:
            newState = {...proxyState, ...selected.reduce(fieldReducer("enabled", Boolean(proxyAction.value)),{})}
            return newState;
        case ProxyActionType.DeleteAllProxies:
            return {};
        case ProxyActionType.ToggleSelect:
            if (!proxyAction.proxies) break;
            newState = {...proxyState, ...proxyAction.proxies.reduce(fieldReducer("selected",Boolean(proxyAction.value)),{}) };
            return newState;
        case ProxyActionType.ToggleSelectAll:
            newState = {...Object.values(proxyState).reduce(fieldReducer("selected", Boolean(proxyAction.value)) ,{}) };
            return newState;
        case ProxyActionType.Revert:
            if (!proxyAction.proxies) break;
            const proxiesToRevert = (state: ProxyState, proxy: ProxyClient) => {
                return {...state, [proxy.ip]: proxy}
            };
            newState = {...proxyState, ...proxyAction.proxies.reduce(proxiesToRevert, {})}
    }

    return proxyState;
}

interface ProxyStateProviderProps {
    proxies?: ProxyClient[];
}

export const ProxyStateProvider = ({children, proxies}: PropsWithChildren<ProxyStateProviderProps>) => {
    const proxyState: ProxyState = proxies?.reduce((proxysState: ProxyState, proxyClient: ProxyClient) => {
        proxysState[proxyClient.ip] = proxyClient
        return proxysState;
    }, {}) ?? {};

    const [proxiesState, dispatch] = useReducer(ProxyReducer, proxyState)
    const state = {state: proxiesState, dispatch};
    return (
        <ProxyStateContext.Provider value={state}>
            {children}
        </ProxyStateContext.Provider>
    );
}